import { useEffect, useState } from "react";
import { Container, ParentContainer } from "./style";
import ToolSearch from "../../fragments/ToolSearch";
import RemovalStats from "../../fragments/RemovalStats";
import { ToolContext } from "./toolContext";
import { getLocationReviewDetails } from "../../services/fetchInitialReview";
import { processReviewAnalyticsChartData } from "../../services/processReviewAndChartData";
import { getLocationDetails } from "../../services/fetchReviewDetails";

interface Reviews {
  "1": number;
  "2": number;
  "3": number;
  "4": number;
  "5": number;
}

const initialReviews = {
  "1": 0,
  "2": 0,
  "3": 0,
  "4": 0,
  "5": 0,
};


function ToolMain({ textData }: any) {
  const [locationData, setLocationData] = useState<any>();
  const [reviewsData, setReviewsData] = useState<Reviews>(initialReviews);
  const [analyticsData, setAnalyticsData] = useState<any>()
  const [reviewAnalyticsFetched, setreviewAnalyticsFetched] = useState(false)
  const [badReviewAnalytics, setbadReviewAnalytics] = useState(false)
  const [launched, setLaunched] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSelect = (data: any) => {
    setLaunched(false);
    setLocationData(data);
    setbadReviewAnalytics(false)
    setreviewAnalyticsFetched(false)
  };

  const onLaunch = async () => {
    if (locationData && locationData.description) {
      setLaunched(true);
      setLoader(true);
      await hydrateLocationDetails(locationData.description)
      await getReviewDetails(locationData.description)
    }
  };

  async function hydrateLocationDetails(query: string) {
    const locationDetails = await getLocationDetails(query)
    if (locationDetails.locationData.reviews_per_score === null) setReviewsData(initialReviews)
    setReviewsData(locationDetails.locationData.reviews_per_score)
    setLocationData({
      name: locationDetails.locationData.name,
      rating: locationDetails.locationData.rating,
      user_ratings_total: locationDetails.locationData.reviews
    })
    setbadReviewAnalytics(false)
    setLoader(false)
    setLaunched(true)
  }

  async function getReviewDetails(query: string) {
    const review = await getLocationReviewDetails(query)
    if (review.locationData.reviews_per_score === null) setReviewsData(initialReviews)
    else setReviewsData(review.locationData.reviews_per_score)
    setLocationData({
      name: review.locationData.name,
      rating: review.locationData.rating,
      user_ratings_total: review.locationData.reviews
    })
    if (!review.noReviewFound) {
      const processedData = processReviewAnalyticsChartData(review)
      setAnalyticsData(processedData)
      setbadReviewAnalytics(true)
    }
    setLoader(false)
    setLaunched(true)
    setreviewAnalyticsFetched(true)
  }

  function getParameterByName(name: any, url = window.location.href) {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const ToolContextData = {
    locationData,
    reviewsData,
    textData,
    analyticsData,
    badReviewAnalytics,
    reviewAnalyticsFetched
  };

  useEffect(() => {
    setbadReviewAnalytics(badReviewAnalytics)
  }, [reviewsData, badReviewAnalytics])


  return (
    <ToolContext.Provider value={ToolContextData}>
      <ParentContainer>
        <Container>
          <ToolSearch onLaunch={onLaunch} onSelect={onSelect} />
          {Boolean((launched && locationData) || getParameterByName("dummy")) && <RemovalStats loading={loader} />}
        </Container>
      </ParentContainer>
    </ToolContext.Provider>
  );
}

export default ToolMain;
