import axios, { AxiosRequestConfig } from 'axios';

export const getLocationDetails = async (query: string): Promise<any> => {
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: process.env.REACT_APP_OUTSCRAPER_ENDPOINT,
    params: {
      query,
      async: false,
      sort: 'lowest_rating',
      cutoffRating: 3,
      reviewsLimit:1,
    },
    headers: {
      'X-API-KEY': process.env.REACT_APP_OUTSCRAPER_KEY
    }
  };

  try {
    const response = await axios.request(options);

    return {
      locationData: response.data.data[0],
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
