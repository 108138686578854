import React, { useContext } from "react";
import {
  StatsAnalyticsParent,
  StatsBusinessHead,
  StatsBusinessParent,
  StatsBusinessSubText,
  StatsBusinessText,
  StatsHead,
  StatsParent,
  RatingSpan,
  ProgressWrapper,
  ProgressText,
  CallText,
  ScheduleWrapper,
  ScheduleButton,
} from "./style";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { ToolContext } from "../../components/tool/toolContext";
import RemovalForeCasts from "../RemovalForecasts";
import BadReviewAnalytics from "../BadReviewAnalytics";
import GraphAnalytics from "../GraphAnalytics";

interface RemovalStatsProps {
  loading: boolean;
}

const RemovalStats: React.FC<RemovalStatsProps> = ({ loading }) => {
  const { locationData, textData, badReviewAnalytics, reviewAnalyticsFetched }: any = useContext(ToolContext);

  return (
    <>
      <StatsHead>Current Review Analytics</StatsHead>
      <StatsParent>
        {/* <BusinessImage src="https://i.ibb.co/q5ndLg5/Screenshot-2024-06-24-at-6-17-00-PM.png"/> */}
        <StatsBusinessParent>
          <StatsBusinessHead>Your Business Today:</StatsBusinessHead>
          <StatsBusinessText>{locationData ? locationData.name : 'Your Bussiness Name'}</StatsBusinessText>
          <Stack spacing={1} style={{ marginTop: "10px", marginBottom: "10px" }} key={locationData}>
            <Rating
              key={locationData ? locationData.rating : 4}
              name="half-rating-read"
              value={locationData ? locationData.rating : 4}
              precision={0.5}
              readOnly
              size="large"
            />
          </Stack>
          <StatsBusinessSubText style={{ marginBottom: "10px" }}>
            <RatingSpan>{locationData ? locationData.rating : 0}</RatingSpan> Rating
          </StatsBusinessSubText>
          <StatsBusinessSubText>
            <RatingSpan>
              {locationData ? locationData.user_ratings_total?.toLocaleString() : 0}
            </RatingSpan>
            Google Reviews
          </StatsBusinessSubText>
        </StatsBusinessParent>
        {loading ? (
          <>
            <ProgressWrapper>
              <ProgressText>We are calculating your reviews</ProgressText>
              <div className="loading-dots">
                <div className="loading-dots--dot"></div>
                <div className="loading-dots--dot"></div>
                <div className="loading-dots--dot"></div>
              </div>
            </ProgressWrapper>
          </>
        ) : (
          <StatsAnalyticsParent>

            <ScheduleWrapper>
              <CallText>
                {textData && textData.contactText ? textData.contactText : `Want to get started on improving your average star rating with Dandy's Ai Powered Technology?`}
              </CallText>
              <ScheduleButton onClick={() => window.open(textData && textData.contactUrl ? textData.contactUrl : "https://meetings.hubspot.com/dandy-/calculator", "_blank")}>
                {textData && textData.contactBtn ? textData.contactBtn : `Schedule a call`}
              </ScheduleButton>
            </ScheduleWrapper>
            <RemovalForeCasts />
          </StatsAnalyticsParent>
        )}
      </StatsParent>
      {badReviewAnalytics && <BadReviewAnalytics />}
      {badReviewAnalytics && <GraphAnalytics />}
      {!reviewAnalyticsFetched && <>
        <ProgressWrapper style={{width:'100%'}}>
          <ProgressText>Calculating Bad Reviews</ProgressText>
          <div className="loading-dots">
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
          </div>
        </ProgressWrapper>
      </>}
      <br />
      <br />
    </>
  );
};

export default RemovalStats;
