import styled from "styled-components";
import { device } from "../../branding/screen";
export const Container = styled.div`
  @media ${device.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media ${device.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: 40px;
  color:${(props) => props.theme.colors.black};
  margin-bottom: 10px;

  @media ${device.mobile} {
    font-size: 26px;
  }

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const SecondaryHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: 25px;
  color:${(props) => props.theme.colors.black};
  margin-bottom: 10px;

  @media ${device.mobile} {
    font-size: 26px;
  }

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const SubHead = styled.p`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.black};
  
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 14px;
  }

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const InputParent = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  padding: 25px;
  margin-top: 25px;
  border-radius: 10px;

  @media ${device.mobile} {
    padding: 10px;
  }
`;

export const InputHead = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.white};

  @media ${device.mobile} {
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: ${(props) => props.theme.lineHeights.medium};
  }
`;

export const InputPara = styled.p`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  margin-top: 15px;
  color: ${(props) => props.theme.colors.white};

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const InputMain = styled.input`
  width: 100%;
  border: none;
  padding: 15px;
  border-radius: 5px;
  outline: green;
  outline-width: 5px;
  font-weight: 400;
  background-color:${(props) => props.theme.colors.boxBackground};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.grey500};
  @media ${device.mobile} {
    height: 20px;
  }
`;

export const InputMainParent = styled.div`
  display: flex;
  gap: 10px;
`;

export const InputButton = styled.button`
  font-size: 15px;
  padding: 15px;
  padding-right: 30px;
  padding-left: 30px;
  border: none;
  background-color: white;
  border-radius: 5px;
  font-weight: 600;
  background-color:${(props) => props.theme.colors.boxBackground};
  color: ${(props) => props.theme.colors.green};
  cursor: pointer;

  @media ${device.mobile} {
    height: 50px;
    padding: 0 10px;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
  margin-top: 50px;
`;

export const DropdownItem = styled.div`
  padding: 14px 12px;
  cursor: pointer;
  // background-color: whitesmoke;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.grey400};
  &:hover {
    background-color: #34d934;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const MapIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const PlayIcon = styled.img`
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
`;
