import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SequelSansVF_Bold';
    src: url('/fonts/SequelSansVF-BodyBold/font.woff2') format('woff2')
         url('/fonts/SequelSansVF-BodyBold/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SequelSansVF_Light';
    src: url('/fonts/SequelSansVF-BodyLight/font.woff2') format('woff2')
         url('/fonts/SequelSansVF-BodyLight/font.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
`;
