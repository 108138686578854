import styled from "styled-components";
import { device } from "../../branding/screen";

export const StatsSecondaryAnalyticsParent = styled.div`
  @media ${device.mobile} {
    position:absolute;
    top:1650px;
    left:65px;
  }

`;

export const ForeCastContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;

export const ForeCastContainerChild = styled.div`
  width: 331px;
  background-color:${(props) => props.theme.colors.boxBackground};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  @media ${device.mobile} {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const ForeCastContainerChildHead = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.green};
  margin-bottom: 10px;
`;

export const ForeCastContainerChildTextParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  color:${(props) => props.theme.colors.black};
`;

export const ForeCastContainerChildTextPrimary = styled.p`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: ${(props) => props.theme.fontSizes.lg};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
`;

export const ForeCastContainerChildTextSecondary = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.bold};
`;
