export const processReviewAnalyticsChartData = (review: any) => {
    const { oldestReview, oldestReviewYear, oldestYearReviewCount, groupedReviews } = review;

    const oldestReviewDate = new Date(oldestReview.review_datetime_utc);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const monthsSinceFirstNegativeReview = (currentDate.getFullYear() - oldestReviewDate.getFullYear()) * 12 + currentDate.getMonth() - oldestReviewDate.getMonth();
    const initialDate = oldestReviewDate.getFullYear() > currentYear - 4 ? oldestReviewYear : currentYear - 4

    const chartYearGraph:any = [];
    for (let year = initialDate; year <= currentYear + 4; year++) {
        chartYearGraph.push(year.toString());
    }

    const yearlyForecast:any = [];
    const growthRate = 0.15;

    for (let year = initialDate; year <= currentYear; year++) {
        const reviewCount:any = groupedReviews[year] ? groupedReviews[year].length : yearlyForecast[yearlyForecast.length -1];
        yearlyForecast.push(reviewCount);
    }

    let lastYearCount:any = yearlyForecast[yearlyForecast.length - 1] || oldestYearReviewCount;

    for (let year = currentYear + 1; year <= currentYear + 4; year++) {
        lastYearCount = Math.round(lastYearCount * (1 + growthRate));
        yearlyForecast.push(lastYearCount);
    }

    return {
        monthsSinceFirstNegativeReview,
        chartYearGraph,
        yearlyForecast,
        oldestReview
    };
};
