import React,{useEffect, useState} from 'react'
import {TopBarParent,Logo} from './style'

function TopBar() {
  const [logoUrl, setLogoUrl] = useState<any>('https://app.getdandy.com/assets/images/getdandy.svg')

  function getParameterByName(name:any, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  useEffect(() => {
    if(getParameterByName('img')) setLogoUrl(getParameterByName('img'))
  }, [])

  return (
    <TopBarParent><Logo src={logoUrl}/></TopBarParent>
  )
}

export default TopBar