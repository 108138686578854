import React, { useContext } from 'react'
import { GraphParent, GraphParentChild, GraphParentMain } from './style'
import { SecondaryHeading } from '../ToolSearch/style'
import { BarChart, LineChart } from '@mui/x-charts';
import { useMediaQuery } from '@mui/material';
import { ToolContext } from '../../components/tool/toolContext';

const xLabels = [
    '1 Star',
    '2 Star',
    '3 Star',
    '4 Star',
    '5 Star'
];

function GraphAnalytics() {
    const { analyticsData , reviewsData }: any = useContext(ToolContext);
    const isMobile = useMediaQuery("(max-width:512px)");
    const negativeArray = !reviewsData["1"] ? [2,5,4,2,4] : [reviewsData["1"],reviewsData["2"],reviewsData["3"],reviewsData["4"],reviewsData["5"]]
    return (
        <GraphParentMain>
            <GraphParent>
                <GraphParentChild>
                    <SecondaryHeading>Review Distribution</SecondaryHeading>
                    <BarChart
                        width={!isMobile ? 538 : 366}
                        height={300}
                        colors={['#25b425']}
                        series={[
                            { data: negativeArray, id: 'pvId', stack: 'total' }
                        ]}
                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                    />
                </GraphParentChild>
                <GraphParentChild>
                    <SecondaryHeading>Cost of Inaction - What Happens if You Do Nothing</SecondaryHeading>
                    <LineChart
                        xAxis={[{ data: analyticsData ? analyticsData.chartYearGraph : ['2020','2021','2022','2023','2024'] , label:'Years',scaleType:'point'}]}
                        series={[
                            {
                                data: analyticsData ? analyticsData.yearlyForecast : [3,7,5,4,2],
                                label:'Total Bad Reviews Over Time'
                            },
                        ]}
                        colors={['#25b425']}
                        width={!isMobile ? 538 : 366}
                        height={300}
                    />
                </GraphParentChild>
            </GraphParent>

        </GraphParentMain>
    )
}

export default GraphAnalytics