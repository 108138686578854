import { useEffect, useState } from "react";
import AutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export interface PlacePrediction {
  place_id: string;
  description: string;
}

export const usePlacesService = (onSelect?: (place: any) => void) => {
  const { placesService, placePredictions, getPlacePredictions } =
    AutocompleteService({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
    });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (placePredictions.length) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [placePredictions]);

  const handleSelect = (placeId: string, description: string) => {
    placesService?.getDetails(
      {
        placeId,
      },
      (placeDetails: any) => {
        if (onSelect) {
          setValue(description);
          onSelect({ ...placeDetails, description });
          setDropdownOpen(false);
        }
      }
    );
  };

  const onBlur = () => {
    setTimeout(() => setDropdownOpen(false), 200);
  };

  const inputProps = {
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
      getPlacePredictions({ input: evt.target.value });
    },
    onBlur,
    value,
  };

  return {
    inputProps,
    dropdownOpen,
    placePredictions,
    handleSelect,
  };
};
