import styled from "styled-components";
import { device } from "../../branding/screen";

export const GraphParentMain = styled.div`
    margin-top:30px;
    display:block;
`;

export const GraphParent = styled.div`
    display:flex;
    gap:10px;

    @media ${device.mobile} {
        flex-direction: column;
        width: 100%;
        position:absolute;
        top:2250px;
    }
`;

export const GraphParentChild = styled.div`
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px;
    border-radius: 10px;
    padding:30px;
`;



