import { useContext } from 'react'
import { StatsSecondaryAnalyticsParent ,  ForeCastContainer, ForeCastContainerChild, ForeCastContainerChildHead, ForeCastContainerChildTextParent, ForeCastContainerChildTextPrimary } from './styles'
import { SecondaryHeading } from '../ToolSearch/style'
import { useMediaQuery } from "@mui/material";
import { ToolContext } from '../../components/tool/toolContext';

function BadReviewAnalytics() {
    const { analyticsData , reviewsData }: any = useContext(ToolContext);
    const isMobile = useMediaQuery("(max-width:512px)");
    const isTablet = useMediaQuery("(max-width:1100px)");
    
    return (
        <StatsSecondaryAnalyticsParent>
            <SecondaryHeading>Review Analytics</SecondaryHeading>
            <br/>
            <ForeCastContainer>
                <ForeCastContainerChild style={{ width: isMobile || isTablet  ? "100%" : "355px" }}>
                    <ForeCastContainerChildHead>Total Bad Reviews</ForeCastContainerChildHead>
                    <br/>
                    <ForeCastContainerChildTextParent>
                        <ForeCastContainerChildTextPrimary>{reviewsData["1"] ? reviewsData["1"] + reviewsData["2"] + reviewsData["3"] : 0}</ForeCastContainerChildTextPrimary>
                    </ForeCastContainerChildTextParent>
                </ForeCastContainerChild>
                <ForeCastContainerChild style={{ width: isMobile || isTablet ? "100%" : "355px" }}>
                    <ForeCastContainerChildHead>Date of the First Bad Review</ForeCastContainerChildHead>
                    <ForeCastContainerChildTextParent>
                        <ForeCastContainerChildTextPrimary>{analyticsData !== undefined ? new Date(analyticsData?.oldestReview.review_datetime_utc).toDateString() : new Date().toDateString()}</ForeCastContainerChildTextPrimary>
                    </ForeCastContainerChildTextParent>
                </ForeCastContainerChild>
                <ForeCastContainerChild style={{ width: isMobile || isTablet ? "100%" : "355px" }}>
                    <ForeCastContainerChildHead>Month Since First Bad Review</ForeCastContainerChildHead>
                    <ForeCastContainerChildTextParent>
                        <ForeCastContainerChildTextPrimary>{analyticsData === undefined ? 0 : `${analyticsData.monthsSinceFirstNegativeReview} Months`}</ForeCastContainerChildTextPrimary>
                    </ForeCastContainerChildTextParent>
                </ForeCastContainerChild>
                {/* <ForeCastContainerChild style={{ width: isMobile || isTablet ? "100%" : "320px" }}>
                    <ForeCastContainerChildHead>Historical Bad Reviews Per Month</ForeCastContainerChildHead>
                    <ForeCastContainerChildTextParent>
                        <ForeCastContainerChildTextPrimary>{'0.80'}</ForeCastContainerChildTextPrimary>
                    </ForeCastContainerChildTextParent>
                </ForeCastContainerChild> */}
            </ForeCastContainer>
        </StatsSecondaryAnalyticsParent>
    )
}
export default BadReviewAnalytics