import React, { useContext } from "react";
import {
  Container,
  Heading,
  InputButton,
  InputHead,
  InputMain,
  InputMainParent,
  InputPara,
  InputParent,
  Dropdown,
  DropdownItem,
  DropdownWrapper,
  MapIcon,
  PlayIcon,
} from "./style";
import { usePlacesService, PlacePrediction } from "../../services/autocomplete";
import { useMediaQuery } from "@mui/material";
import { ToolContext } from "../../components/tool/toolContext";
interface ToolSearchProps {
  onSelect: (place: any) => void;
  onLaunch: () => void;
}

const ToolSearch: React.FC<ToolSearchProps> = ({ onSelect, onLaunch }) => {
  const { inputProps, dropdownOpen, placePredictions, handleSelect } = usePlacesService(onSelect);
  const isMobile = useMediaQuery("(max-width:512px)");
  const {textData}:any = useContext(ToolContext)

  return (
    <Container>
      <Heading>Google My Business ROI Calculator</Heading>
      <InputParent>
        <InputHead>Find your business:</InputHead>
        <InputMainParent>
          <DropdownWrapper>
            <InputMain {...inputProps}  placeholder="Enter Business Name" />
            {dropdownOpen && (
              <Dropdown>
                {placePredictions.map((item: PlacePrediction) => (
                  <DropdownItem key={item.place_id} onClick={() => handleSelect(item.place_id, item.description)}>
                    <MapIcon src={`${process.env.PUBLIC_URL}/images/map.svg`} /> {item.description}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </DropdownWrapper>
          <InputButton onClick={onLaunch}>
            {isMobile ? <PlayIcon src={`${process.env.PUBLIC_URL}/images/play.png`} /> : "Launch"}
          </InputButton>
        </InputMainParent>
        <InputPara>
        { textData && textData.boxText ? textData.boxText : `How do we know? We calculate this projection on results from over 1,000 businesses that are currently using
          Dandy to improve their Google My Business profiles.` }
        </InputPara>
      </InputParent>
    </Container>
  );
};

export default ToolSearch;
