import styled from "styled-components";
import { device } from "../../branding/screen";

export const Container = styled.div``;

export const Heading = styled.h1`
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
  color: ${(props) => props.theme.colors.black};

  @media ${device.mobile} {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const ForeCastContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;

export const ForeCastContainerChild = styled.div`
  width: 400px;
  background-color:${(props) => props.theme.colors.boxBackground};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  @media ${device.mobile} {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const ForeCastContainerChildHead = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.green};
  margin-bottom: 10px;
`;

export const ForeCastContainerChildTextParent = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  color:${(props) => props.theme.colors.black};
`;

export const ForeCastContainerChildTextPrimary = styled.p`
  font-family: ${(props) => props.theme.fontFamily.bold};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
`;

export const ForeCastContainerChildTextSecondary = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.bold};
`;
