import { useEffect, useState } from "react";
import "./index.css";
import ToolMain from "./components/tool/index";
import TopBar from "./fragments/TopBar";
import { ThemeProvider } from "styled-components";
import theme from "./branding/theme";
import { GlobalStyle } from "./branding/globalFont";
import axios from "axios";

function App() {
  const [themeRaw, setthemeRaw] = useState<any>(null);
  const [background, setBackground] = useState<any>(null)
  const [revoked, setRevoked] = useState(false)
  const [titleSub, setTitleSub] = useState<any>(null)
  const [boxText, setBoxText] = useState<any>(null)
  const [contactText, setContactText] = useState<any>(null)
  const [contactBtn, setContactBtn] = useState<any>(null)
  const [contactUrl, setContactUrl] = useState<any>(null)
  
  function getParameterByName(name:any, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function hydrateColors() {
    const themeMain = theme;
    if(getParameterByName('accent')) themeMain.colors.green = '#'+getParameterByName('accent');
    if(getParameterByName('head')) themeMain.colors.black = "#"+getParameterByName('head');
    if(getParameterByName('boxText')) themeMain.colors.white = "#"+getParameterByName('boxText');
    if(getParameterByName('boxes')) themeMain.colors.boxBackground = "#"+getParameterByName('boxes');
    if(getParameterByName('background')) setBackground("#"+getParameterByName('background'))
    if(getParameterByName('titleSub')) setTitleSub(getParameterByName('titleSub'))
    if(getParameterByName('boxTextColor')) setBoxText(getParameterByName('boxTextColor'))
    if(getParameterByName('contactText')) setContactText(getParameterByName('contactText'))
    if(getParameterByName('contactBtn')) setContactBtn(getParameterByName('contactBtn'))
    if(getParameterByName('contactUrl')) setContactUrl(getParameterByName('contactUrl'))
    setthemeRaw(themeMain);
  }

  useEffect(() => {
    if(window !== window.parent && getParameterByName('preview') == null){
        axios.get(`${process.env.REACT_APP_BACKEND_MAIN_URL}merchants/validate/calculator/iframe`,{params:{id:getParameterByName('id')}})
          .then(({data}) => {
            if(data.iframeCalculatorAddOn === true) hydrateColors()
            else setRevoked(true)
          }).catch((err) => console.log(err))
    } else if(window !== window.parent && getParameterByName('preview')) hydrateColors()
    else setthemeRaw(theme);
  },[]);

  if (themeRaw && !revoked)
    return (
      <ThemeProvider theme={themeRaw}>
        {background && <style dangerouslySetInnerHTML={{ __html: `body { background-color: ${background} !important; }`}}/>}
        <GlobalStyle />
        <TopBar />
        <ToolMain textData={{titleSub,boxText,contactText,contactBtn,contactUrl}}/>
      </ThemeProvider>
    );
  else return <></>;
}

export default App;
