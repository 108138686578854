import styled from "styled-components";
import { device } from "../../branding/screen";

export const StatsHead = styled.h1`
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
  color: ${(props) => props.theme.colors.black};

  @media ${device.mobile} {
    font-size: 26px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const BusinessImage = styled.img`
  width:450px;
  border-radius:10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`

export const StatsParent = styled.div`
  height:390px;
  margin-top: 15px;
  margin-bottom: 50px;
  display: flex;
  gap: 18px;

  @media ${device.tablet} {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media ${device.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StatsBusinessParent = styled.div`
  background-color:${(props) => props.theme.colors.boxBackground};
  width: 300px;
  height: 100%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  @media ${device.tablet} {
    width: 100%;
    padding: 20px;
    height: auto;
  }
  @media ${device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

export const StatsBusinessHead = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.bold};
  color: ${(props) => props.theme.colors.green};
  font-size: 46px;
  line-height: 46px;
  padding-top: 30px;
  padding-bottom: 30px;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
`;

export const RatingSpan = styled.span`
  font-size: 22px;
  margin-right: 10px;
  font-family: ${(props) => props.theme.fontFamily.light};
`;

export const StarBox = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 10px;
`;

export const StatsBusinessStar = styled.img`
  width: 35px;
`;

export const StatsBusinessText = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.lg};
  line-height: ${(props) => props.theme.lineHeights.lg};

  @media ${device.mobile} {
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: ${(props) => props.theme.lineHeights.medium};
    text-align: center;
  }
`;

export const StatsBusinessSubText = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: 16px;
  color:${(props) => props.theme.colors.black};
`;

export const StatsBusinessActionText = styled.h4``;

export const StatsBusinessActionsParent = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 10px;
`;

export const StatsBusinessActions = styled.img`
  width: 35px;
`;

export const StatsAnalyticsParent = styled.div``;

export const StatsSecondaryAnalyticsParent = styled.div``;

export const StatsParentMain = styled.div``;


export const StatsAnalyticsCardMain = styled.div`

`;

export const StatsAnalyticsCard = styled.div`
  background-color:${(props) => props.theme.colors.boxBackground};
  width: 681px;
  height: 39%;
  border-radius: 10px;
  padding: 36px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  @media ${device.tablet} {
    width: 100%;
    padding: 20px;
    height: auto;
  }

  @media ${device.mobile} {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
  }
`;

export const StatsAnalyticsCardHead = styled.h2`
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
  color: ${(props) => props.theme.colors.green};

  @media ${device.tablet} {
  }

  @media ${device.mobile} {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const StatsAnalyticsCardStatsParent = styled.div`
  display: flex;
  gap: 50px;

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StatsAnalyticsCardStatsParentRatingParent = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const StatsAnalyticsCardStatsParentRating = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.extraLarger};
  line-height: ${(props) => props.theme.lineHeights.extraLarger};
  color: ${(props) => props.theme.colors.black};
  font-size: 40px;
`;

export const StatsAnalyticsCardStatsParentText = styled.p`
  margin-top: 20px;
  font-family: ${(props) => props.theme.fontFamily.light};
  color: ${(props) => props.theme.colors.green};
  font-size: 15px;
`;

export const StatsAnalyticsCardStatsParentRatingText = styled.h5`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: 15px;
  width: 100px;
  color:${(props) => props.theme.colors.black};
`;

export const ConversionImage = styled.img`
  width: 70px;

  @media ${device.mobile} {
    transform: rotate(90deg);
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40vh;
  align-items: center;
  width: 60%;
  flex-direction: column;

  @media ${device.tablet} {
    width: 100%;
    text-align: center;
    height: 20vh;
  }

  @keyframes dot-keyframes {
    0% {
      opacity: 0.4;
      transform: scale(1, 1);
    }

    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }

    100% {
      opacity: 0.4;
      transform: scale(1, 1);
    }
  }

  .loading-dots {
    text-align: center;
    width: 100%;
    margin-top: 10px;

    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: ${(props) => props.theme.colors.green};
      border-radius: 10px;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 3px;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }
`;

export const ProgressText = styled.p`
  margin-top: 20px;
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: 35px;

  @media ${device.mobile} {
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: ${(props) => props.theme.lineHeights.medium};
    margin-top: 0;
  }
`;

export const CallText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color:${(props) => props.theme.colors.black};

  @media ${device.tablet} {
    text-align: center;
  }
`;

export const ScheduleWrapper = styled.div`
  max-width: 887px;
  display: flex;
  gap: 10px;
  margin: 40px 0;
  justify-content:center;
  align-items:center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ScheduleButton = styled.button`
  flex-basis: 40%;
  background-color: ${(props) => props.theme.colors.green};
  border: none;
  border-radius: 4px;
  font-family: ${(props) => props.theme.fontFamily.light};
  font-size: ${(props) => props.theme.fontSizes.default};
  line-height: ${(props) => props.theme.lineHeights.default};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  height:45px;

  @media ${device.tablet} {
    padding: 10px;
  }
`;
