import styled from "styled-components";

export const TopBarParent = styled.div`
  height: 65px;
  border-bottom: 1px solid #80808087;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 180px;
`;
