import React, { useContext } from "react";
import { ToolContext } from "../../components/tool/toolContext";
import {
  Container,
  ForeCastContainer,
  ForeCastContainerChild,
  ForeCastContainerChildHead,
  ForeCastContainerChildTextParent,
  ForeCastContainerChildTextPrimary,
} from "./styles";
import { useMediaQuery } from "@mui/material";
import { SecondaryHeading } from "../ToolSearch/style";

function RemovalForeCasts() {
  const { reviewsData }: any = useContext(ToolContext);
  const isMobile = useMediaQuery("(max-width:512px)");

  return (
    <Container>
      <SecondaryHeading>Review Rating Analytics</SecondaryHeading>
      <ForeCastContainer>
        <ForeCastContainerChild style={{ width: isMobile ? "100%" : "250px" }}>
          <ForeCastContainerChildHead>1 Star Reviews</ForeCastContainerChildHead>
          <ForeCastContainerChildTextParent>
            <ForeCastContainerChildTextPrimary>{ reviewsData ? reviewsData["1"] : 0}</ForeCastContainerChildTextPrimary>
            {/* <ForeCastContainerChildTextSecondary>month over month</ForeCastContainerChildTextSecondary> */}
          </ForeCastContainerChildTextParent>
        </ForeCastContainerChild>
        <ForeCastContainerChild style={{ width: isMobile ? "100%" : "250px" }}>
          <ForeCastContainerChildHead>2 Star Reviews</ForeCastContainerChildHead>
          <ForeCastContainerChildTextParent>
            <ForeCastContainerChildTextPrimary>{ reviewsData ? reviewsData["2"] : 0}</ForeCastContainerChildTextPrimary>
            {/* <ForeCastContainerChildTextSecondary>month over month</ForeCastContainerChildTextSecondary> */}
          </ForeCastContainerChildTextParent>
        </ForeCastContainerChild>
        <ForeCastContainerChild style={{ width: isMobile ? "100%" : "250px" }}>
          <ForeCastContainerChildHead>3 Star Reviews</ForeCastContainerChildHead>
          <ForeCastContainerChildTextParent>
            <ForeCastContainerChildTextPrimary>{reviewsData ? reviewsData["3"] : 0}</ForeCastContainerChildTextPrimary>
            {/* <ForeCastContainerChildTextSecondary>month over month</ForeCastContainerChildTextSecondary> */}
          </ForeCastContainerChildTextParent>
        </ForeCastContainerChild>
      </ForeCastContainer>
      <ForeCastContainer style={{ marginTop: "13px" }}>
        <ForeCastContainerChild>
          <ForeCastContainerChildHead>4 Star Reviews</ForeCastContainerChildHead>
          <ForeCastContainerChildTextParent>
            <ForeCastContainerChildTextPrimary>{reviewsData ? reviewsData["4"] : 0}</ForeCastContainerChildTextPrimary>
            {/* <ForeCastContainerChildTextSecondary>month over month</ForeCastContainerChildTextSecondary> */}
          </ForeCastContainerChildTextParent>
        </ForeCastContainerChild>
        <ForeCastContainerChild>
          <ForeCastContainerChildHead>5 Star Reviews</ForeCastContainerChildHead>
          <ForeCastContainerChildTextParent>
            <ForeCastContainerChildTextPrimary>{reviewsData ? reviewsData["5"] : 0}</ForeCastContainerChildTextPrimary>
            {/* <ForeCastContainerChildTextSecondary>month over month</ForeCastContainerChildTextSecondary> */}
          </ForeCastContainerChildTextParent>
        </ForeCastContainerChild>
      </ForeCastContainer>
    </Container>
  );
}

export default RemovalForeCasts;
