const theme = {
  colors: {
    green: "#25B425",
    boxBackground: "#ffffff",
    lightGreen: "#89DB7A",
    black: "#10222D",
    white: "#FFFFFF",
    grey100: "#F0F3F5",
    grey200: "#E4EBF2",
    grey300: "#CFDAE5",
    grey400: "#92A2B0",
    grey500: "#405F7A",
    grey600: "#193647",
  },
  fontColors: {
    primary: "black",
    secondary: "white",
  },
  fontFamily: {
    light: "'SequelSansVF_Light', sans-serif;",
    bold: "'SequelSansVF_Bold', sans-serif;",
  },
  fontSizes: {
    smaller: "10px",
    small: "12px",
    default: "16px",
    medium: "20px",
    lg: "24px",
    extraLarger: "32px",
  },
  lineHeights: {
    extraSmall: "14px",
    smaller: "17px",
    small: "20px",
    default: "22px",
    medium: "24px",
    lg: "28px",
    extraLarger: "32px",
  },
};

export default theme;
